/* tslint:disable */
/* eslint-disable */

import * as APITypes from "../models/API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getWeblab = /* GraphQL */ `query GetWeblab($id: Int!) {
  getWeblab(id: $id) {
    id
    name
    description
    __typename
  }
}
` as GeneratedQuery<APITypes.GetWeblabQueryVariables, APITypes.GetWeblabQuery>;
export const getWeblabByName = /* GraphQL */ `query GetWeblabByName($input: GetWeblabByNameInput!) {
  getWeblabByName(input: $input) {
    id
    name
    bar_raiser_wiki
    description
    global_init_name
    experiment_type_id
    page_type_id
    owners
    weblab_team
    deep_dive_link
    __typename
  }
}
` as GeneratedQuery<APITypes.GetWeblabByNameQueryVariables, APITypes.GetWeblabByNameQuery>;
export const listWeblabs = /* GraphQL */ `query listWeblabs( $input: GetTeamPreferencesInput!, $teamInput: ListTeamWeblabsInput!, $limit: Int ) {
  listWeblabs(limit: $limit) {
      id
      name
      description
      bar_raiser_wiki
      experiment_type_id
      global_init_name
      page_type_id
      owners
      weblab_team
      deep_dive_link
      __typename
  }
  listTeamWeblabs(input: $teamInput) {
      analysis_duration
      control
      created_ts
      device_type_id
      id
      is_gating
      launch_date
      marketplace_id
      smoke_test
      start_date
      team_id
      treatment
      updated_ts
      user_id
      weblab_id
      weblab_status_id
      sp_revenue_prob 
      sp_revenue_lift
      sp_revenue_impact
      ops_prob
      ops_lift 
      ops_impact
      gccp_prob
      gccp_lift 
      gccp_impact 
      net_cp_prob
      net_cp_lift
      net_cp_impact
      sp_clicks_prob
      sp_clicks_lift
      sp_clicks_impact
      paid_units_prob
      paid_units_lift
      paid_units_impact
      csales_prob
      csales_lift
      csales_impact
      sb_revenue_prob
      sb_revenue_lift
      sb_revenue_impact
      gating_weblab 
      rev_share 
      revenue_impact_planned_weblab 
      is_chosen_baseline_treatment 
      irrelevance_rate_control 
      irrelevance_rate_treatment 
      is_paste_manual_reports 
      analysis_start_date 
      analysis_end_date 
      job_ids 
      pirrel_human_relevance_audit 
      ctr 
      coverage 
      cpc 
      acos 
      is_manual_weblab
      last_updated_by
  }
  getTeamPreferences(input: $input) {
      id
      team_id
      team_weblab_id
      watch_flag
      comments
      additional_columns
      __typename
  }
}
` as GeneratedQuery<APITypes.ListWeblabsQueryVariables, APITypes.ListWeblabsQuery>;

export const getMetadata = /* GraphQL */ `query getMetadata($input: GetUsersByTeamIdInput!) {
    getTeams {
        id
        name
        custom_attributes
        __typename
    }
    getUsers {
        id
        user_name
        __typename
    }
    getPageTypes {
        id
        name
        __typename
    }
    getDeviceTypes {
        id
        name
        __typename
    }
    getExperimentTypes {
        id
        name
        __typename
    }
    getWeblabStatuses {
        id
        name
        __typename
    }
    getMarketplaces {
        marketplace_id
        marketplace
        region
        display_order
        __typename
    }
    getUsersByTeamId(input: $input) {
        id
        user_name
        __typename
    }
    getPendingUsersByTeamId(input: $input) {
        id
        user_name
        __typename
    }
}
` as GeneratedQuery<APITypes.GetMetadataQueryVariables, APITypes.GetMetadataQuery>;
export const getTeamsByUserName = /* GraphQL */ `query GetTeamsByUserName($input: GetTeamsByUserNameInput!) {
  getTeamsByUserName(input: $input) {
    id
    name
    custom_attributes
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamsByUserNameQueryVariables, APITypes.GetTeamsByUserNameQuery>;
export const getUsersByTeamId = /* GraphQL */ `query GetUsersByTeamId($input: GetUsersByTeamIdInput!) {
  getUsersByTeamId(input: $input) {
    id
    user_name
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUsersByTeamIdQueryVariables, APITypes.GetUsersByTeamIdQuery>;
export const getTeamByTeamId = /* GraphQL */ `query GetTeamByTeamId($input: GetTeamByTeamIdInput!) {
  getTeamByTeamId(input: $input) {
    id
    name
    custom_attributes
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTeamByTeamIdQueryVariables, APITypes.GetTeamByTeamIdQuery>;
export const getHelpContent = /* GraphQL */ `query GetHelpContent {
  getHelpContent {
    content
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHelpContentQueryVariables, APITypes.GetHelpContentQuery>;
export const getTeams = /* GraphQL */ `query getTeams {
    getTeams {
        id
        name
        custom_attributes
        __typename
    }
}
` as GeneratedQuery<APITypes.GetTeamsQueryVariables, APITypes.GetTeamsQuery>;

export const getAnalysisStartDates = /* GraphQL */ `query getAnalysisStartDates($input: GetAnalysisStartDatesInput!)   {
    getAnalysisStartDates(input: $input) {
        analysis_start_date
        __typename
    }
}
` as GeneratedQuery<APITypes.GetAnalysisStartDatesQueryVariables, APITypes.GetAnalysisStartDatesQuery>;

export const getWeblabMetrics = /* GraphQL */ `query getWeblabMetrics($input: GetWeblabMetricsInput!)   {
    getWeblabMetrics(input: $input) {
        name
        marketplace_id
        control
        treatment
        analysis_duration
        start_date
        analysis_end_date
        job_ids
        sp_revenue_prob
        sp_revenue_lift
        sp_revenue_impact
        ops_prob
        ops_lift
        ops_impact
        gccp_prob
        gccp_lift
        gccp_impact
        net_cp_prob
        net_cp_lift
        net_cp_impact
        sp_clicks_prob
        sp_clicks_lift
        sp_clicks_impact
        paid_units_prob
        paid_units_lift
        paid_units_impact
        csales_prob
        csales_lift
        csales_impact
        sb_revenue_prob
        sb_revenue_lift
        sb_revenue_impact
        __typename
    }
}
` as GeneratedQuery<APITypes.GetWeblabMetricsQueryVariables, APITypes.GetWeblabMetricsQuery>;
