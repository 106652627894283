import React from "react";
import {
  Box,
  Grid,
  Paper,
  Typography,
  List,
  ListItem,
  Tooltip,
  IconButton,
  TextField,
  Link,
} from "@mui/material";

import { WeblabRowType } from "../models/API";
import { styled } from "@mui/material/styles";
import { currencyFormatter, numberFormatter } from "./utils/formatters";
import { ContentCopyRounded } from "@mui/icons-material";
import "flag-icon-css/css/flag-icons.min.css";
import { FlagIcon } from "./utils/FlagIcon";
import { StatusIcon } from "./utils/StatusIcon";

const StyledGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  //padding: theme.spacing(1),
  "&:not(:last-child)": {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

interface DetailsPanelProps {
  selectedRowData: WeblabRowType | null;
}

const DetailsPanel: React.FC<DetailsPanelProps> = ({ selectedRowData }) => {
  function buildSection1(data: WeblabRowType) {
    let items: Array<any> = [];
    items.push({
      label: "ID",
      value: data.id,
    });
    items.push({
      label: "Weblab Team",
      value: data.weblab_team,
    });
    items.push({
      label: "Owners",
      value: data.owners,
    });
    items.push({
      label: "Experiment Type",
      value: data.experiment_type,
    });
    items.push({
      label: "Smoke Test",
      value: data.smoke_test ? "True" : "False",
    });
    items.push({
      label: "Experiment Start Date",
      value: data.start_date.substring(0, 10),
    });
    items.push({
      label: "Analysis Duration",
      value: data.analysis_duration,
    });
    items.push({
      label: "Page Type",
      value: data.page_type,
    });
    items.push({
      label: "Device",
      value: data.device,
    });
    items.push({
      label: "Creator",
      value: data.user_name,
    });
    items.push({
      label: "Last Updated By",
      value: data.last_updated_by_user_name,
    });
    items.push({
      label: "Last Updated Time",
      value: data.updated_ts,
    });
    return items;
  }

  function buildSection2(data: WeblabRowType) {
    let items: Array<any> = [];
    items.push({
      label: "Status",
      value: data.status,
    });
    items.push({
      label: "Is Gating Weblab",
      value: data.is_gating ? "True" : "False",
    });
    items.push({
      label: "Experiment Weblab",
      value: data.gating_weblab,
    });
    items.push({
      label: "Rev Share(%)",
      value: data.rev_share,
    });
    items.push({
      label: "Expected Launch Date",
      value: data.launch_date.substring(0, 10),
    });
    items.push({
      label: "Is Manual Weblab",
      value: data.is_manual_weblab ? "True" : "False",
    });
    items.push({
      label: "Irrelevance Rate Control(%)",
      value: data.irrelevance_rate_control,
    });
    items.push({
      label: "Irrelevance Rate Treatment(%)",
      value: data.irrelevance_rate_treatment,
    });
    items.push({
      label: "Rev Impact-Planned Weblab",
      value: data.revenue_impact_planned_weblab,
    });
    items.push({
      label: "Is Chosen Baseline Treatment",
      value: data.is_chosen_baseline_treatment ? "True" : "False",
    });
    return items;
  }

  function buildMetrics(data: WeblabRowType) {
    let metrics: Array<any> = [];
    metrics.push({
      label: "",
      prob: "Prob",
      lift: "Lift",
      impact: "Impact",
    });
    metrics.push({
      label: "SP Revenue",
      prob: data.sp_revenue_prob,
      lift: data.sp_revenue_lift,
      impact: data.sp_revenue_impact,
    });
    metrics.push({
      label: "OPS",
      prob: data.ops_prob,
      lift: data.ops_lift,
      impact: data.ops_impact,
    });
    metrics.push({
      label: "GCCP",
      prob: data.gccp_prob,
      lift: data.gccp_lift,
      impact: data.gccp_impact,
    });
    metrics.push({
      label: "Net CP",
      prob: data.net_cp_prob,
      lift: data.net_cp_lift,
      impact: data.net_cp_impact,
    });
    metrics.push({
      label: "SP Clicks",
      prob: data.sp_clicks_prob,
      lift: data.sp_clicks_lift,
      impact: data.sp_clicks_impact,
    });
    metrics.push({
      label: "Paid Units",
      prob: data.paid_units_prob,
      lift: data.paid_units_lift,
      impact: data.paid_units_impact,
    });
    metrics.push({
      label: "CSales",
      prob: data.csales_prob,
      lift: data.csales_lift,
      impact: data.csales_impact,
    });
    metrics.push({
      label: "SB Revenue",
      prob: data.sb_revenue_prob,
      lift: data.sb_revenue_lift,
      impact: data.sb_revenue_impact,
    });
    return metrics;
  }

  function buildSection4(data: WeblabRowType) {
    let items: Array<any> = [];
    items.push({
      label: "Analysis Start Date",
      value: data.analysis_start_date
        ? data.analysis_start_date.substring(0, 10)
        : "",
    });
    items.push({
      label: "Analysis End Date",
      value: data.analysis_end_date
        ? data.analysis_end_date.substring(0, 10)
        : "",
    });
    items.push({
      label: "APT Job IDs",
      value: data.job_ids,
    });
    items.push({
      label: "pIrrel/Human Relevance Audit",
      value: data.pirrel_human_relevance_audit,
    });
    items.push({
      label: "CTR",
      value: data.ctr,
    });
    items.push({
      label: "Coverage",
      value: data.coverage,
    });
    items.push({
      label: "CPC",
      value: data.cpc,
    });
    items.push({
      label: "ACoS",
      value: data.acos,
    });
    return items;
  }
  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error(err);
    }
  };

  function buildAPTJobIDsLinks(weblabID: string, jobIDs: string) {
    const ids = jobIDs.split(/[,; ]+/); // commas, semicolons, and spaces
    const filteredIDs = ids.filter((item) => item !== "");
    return (
      <div>
        <Typography>
          {filteredIDs.map((id: any, index: any) => (
            <a
              key={index}
              href={
                "https://apttool.amazon.com/weblab/regression_results/?jobID=" +
                id +
                "&weblabID=" +
                weblabID +
                "&display_impact_as=annualized&display_results_as=bayesian&currency=usd"
              }
              target={"_blank"}
              style={{ textDecoration: "none", color: "blue" }}
              rel="noreferrer"
            >
              {id}
              {index < filteredIDs.length - 1 ? ", " : ""}
            </a>
          ))}
        </Typography>
      </div>
    );
  }

  function buildPhoneToolLinks(userNames: string) {
    // eslint-disable-next-line no-control-regex
    userNames = userNames.replace(/[^\x00-\x7F]/g, ""); // remove non-ascii characters
    const names = userNames.split(/[,; ]+/); // commas, semicolons, and spaces
    const filteredNames = names.filter((item) => item !== "");
    return (
      <div>
        <Typography>
          {filteredNames.map((name: any, index: any) => (
            <a
              key={index}
              href={"https://phonetool.amazon.com/users/" + name}
              target={"_blank"}
              style={{ textDecoration: "none", color: "blue" }}
              rel="noreferrer"
            >
              {name}
              {index < filteredNames.length - 1 ? ", " : ""}
            </a>
          ))}
        </Typography>
      </div>
    );
  }

  if (selectedRowData) {
    const section1: Array<any> = buildSection1(selectedRowData);
    const section2: Array<any> = buildSection2(selectedRowData);
    const metrics: Array<any> = buildMetrics(selectedRowData);
    const section4: Array<any> = buildSection4(selectedRowData);
    return (
      <Box p={2}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <a
            href={"https://weblab.amazon.com/wl/" + selectedRowData.name}
            target={"_blank"}
            style={{ textDecoration: "none", color: "blue" }}
            rel="noreferrer"
          >
            <Typography
              variant="h5"
              gutterBottom
              style={{ textDecoration: "underline" }}
            >
              {selectedRowData.name}
            </Typography>
          </a>
          <Tooltip title={"Copy Weblab ID"}>
            <IconButton
              onClick={() => copyToClipboard(selectedRowData.name)}
              size={"small"}
            >
              <ContentCopyRounded fontSize={"small"} />
            </IconButton>
          </Tooltip>
        </div>
        <Typography variant="subtitle1" gutterBottom>
          {selectedRowData.global_init_name
            ? selectedRowData.global_init_name + " | "
            : ""}
          {selectedRowData.deep_dive_link ? (
            <b>
              <Link
                href={selectedRowData.deep_dive_link}
                target={"_blank"}
                rel={"noopener noreferrer"}
              >
                Deep Dive Link
              </Link>
              <b>&nbsp;&nbsp;|&nbsp;&nbsp;</b>
            </b>
          ) : (
            ""
          )}
          <b>
            <Link
              href={
                "https://w.amazon.com/bin/view/Weblab/BarRaiser/" +
                selectedRowData.name
              }
              target={"_blank"}
              rel={"noopener noreferrer"}
            >
              Bar Raiser Wiki
            </Link>
          </b>
        </Typography>
        <Typography variant="subtitle2" gutterBottom>
          <b>
            Marketplace:
            {FlagIcon(selectedRowData.marketplace)}
            <b>{selectedRowData.marketplace}</b>
            &nbsp;&nbsp;|&nbsp;&nbsp;Baseline Treatment:&nbsp;
            <b>{selectedRowData.control}</b>&nbsp;&nbsp;|&nbsp;&nbsp;Experiment
            Treatment:&nbsp;
            <b>{selectedRowData.treatment}</b>
          </b>
        </Typography>
        <Typography variant="body1" gutterBottom>
          {selectedRowData.description}
        </Typography>

        <Grid container spacing={2} marginTop={2}>
          {[section1, section2].map((section, index) => (
            <Grid item xs={12} sm={4} key={"section" + index}>
              <Paper elevation={3} sx={{ padding: 1 }}>
                <List dense>
                  {section.map((item: any, i: number) => (
                    <ListItem key={i} divider>
                      <StyledGrid container key={index}>
                        <Grid item sm={index === 0 ? 5 : 7}>
                          <Typography fontWeight="fontWeightBold">
                            {item.label}
                          </Typography>
                        </Grid>
                        <Grid item sm={index === 0 ? 7 : 5}>
                          {item.label === "Creator" ||
                          item.label === "Owners" ||
                          item.label === "Last Updated By" ? (
                            buildPhoneToolLinks(item.value)
                          ) : item.label === "Status" ? (
                            <Typography>
                              {StatusIcon(item.value)}
                              {item.value}
                            </Typography>
                          ) : item.label === "Rev Impact-Planned Weblab" ? (
                            <Typography>
                              {currencyFormatter(item.value)}
                            </Typography>
                          ) : (
                            <Typography>{item.value}</Typography>
                          )}
                        </Grid>
                      </StyledGrid>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          ))}
          <Grid item xs={12} sm={4}>
            {selectedRowData.comments && (
              <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
                <Typography fontWeight={"fontWeightBold"}>Comments</Typography>
                <TextField
                  fullWidth
                  value={selectedRowData.comments}
                  multiline={true}
                  variant={"outlined"}
                  InputProps={{
                    readOnly: true,
                    style: { fontSize: 14 },
                  }}
                />
              </Paper>
            )}
            {(selectedRowData.is_manual_weblab ||
              selectedRowData.analysis_start_date) && (
              <Box>
                <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
                  <List dense>
                    {metrics.map((item, i) => (
                      <ListItem key={i} divider>
                        <Grid container spacing={1}>
                          <Grid item xs={3}>
                            <Typography fontWeight="fontWeightBold">
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item xs={2}>
                            <Typography>{item.prob}</Typography>
                          </Grid>
                          <Grid item xs={2.5}>
                            <Typography>
                              {item.lift}
                              {i === 0 || item.left === "" || item.lift === null
                                ? ""
                                : "%"}
                            </Typography>
                          </Grid>
                          <Grid item xs={4.5}>
                            <Typography>
                              {i === 0 ||
                              item.impact === null ||
                              item.impact === "" ||
                              item.label === "SP Clicks" ||
                              item.label === "Paid Units"
                                ? numberFormatter(item.impact)
                                : currencyFormatter(item.impact)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
                <Paper elevation={3} sx={{ padding: 1 }}>
                  <List dense>
                    {section4.map((item: any, i: number) => (
                      <ListItem key={i} divider>
                        <StyledGrid container>
                          <Grid item sm={5}>
                            <Typography fontWeight="fontWeightBold">
                              {item.label}
                            </Typography>
                          </Grid>
                          <Grid item sm={7}>
                            {item.label === "APT Job IDs" ? (
                              buildAPTJobIDsLinks(
                                selectedRowData.name,
                                item.value
                              )
                            ) : (
                              <Typography>{item.value}</Typography>
                            )}
                          </Grid>
                        </StyledGrid>
                      </ListItem>
                    ))}
                  </List>
                </Paper>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  } else {
    return <div></div>;
  }
};

export default DetailsPanel;
